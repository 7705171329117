// extracted by mini-css-extract-plugin
export var dots = "layout-module--dots--f49fb";
export var fade = "layout-module--fade--4a057";
export var fadeAndPeek = "layout-module--fade-and-peek--cd485";
export var fadeAndPeekAnimation = "layout-module--fadeAndPeekAnimation--11c76";
export var fadeFromRight = "layout-module--fadeFromRight--e2077";
export var main = "layout-module--main--bb199";
export var modalSlideIn = "layout-module--modal-slide-in--d289a";
export var modalSlideOut = "layout-module--modal-slide-out--72fd9";
export var navbar__brand = "layout-module--navbar__brand--6fba3";
export var navbar__right = "layout-module--navbar__right--e4894";
export var notification = "layout-module--notification--fd960";
export var slideDownAndUp = "layout-module--slideDownAndUp--920a6";
export var slideUpAndDown = "layout-module--slideUpAndDown--2293f";
export var slidingModalSlideIn = "layout-module--sliding-modal-slide-in--c838a";
export var slidingModalSlideOut = "layout-module--sliding-modal-slide-out--ba23a";
export var toast = "layout-module--toast--46955";
export var toast__message = "layout-module--toast__message--7bf46";