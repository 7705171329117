import {
  saveContextToSession,
  getContextFromSession,
  removeContextFromSession,
} from "../services/context"
import { isObjectEmpty } from "../services/general"

import { initialState } from "./AppContext"

const SAVE_REIMBURSEMENT_FOR = "SAVE_REIMBURSEMENT_FOR"
const SAVE_AGREE_FLEXMED = "SAVE_AGREE_FLEXMED"
const SAVE_AGREE_MEDENSURE = "SAVE_AGREE_MEDENSURE"
const SAVE_MEDENSURE_PRESCRIBING_DOCTOR = "SAVE_MEDENSURE_PRESCRIBING_DOCTOR"
const SAVE_AGREE_CONSENT_FLEXMED = "SAVE_AGREE_CONSENT_FLEXMED"
const SAVE_AGREE_VIBER_CONSENT_FLEXMED = "SAVE_AGREE_VIBER_CONSENT_FLEXMED"
const SAVE_AGREE_CONSENT_MEDENSURE = "SAVE_AGREE_CONSENT_MEDENSURE"
const SAVE_AGREE_VIBER_CONSENT_MEDENSURE = "SAVE_AGREE_VIBER_CONSENT_MEDENSURE"
const SAVE_AGREE_EPHARMACY = "SAVE_AGREE_EPHARMACY"
const SAVE_MEDICINES = "SAVE_MEDICINES"
const SAVE_ORDERING_FOR = "SAVE_ORDERING_FOR"
const SAVE_DOCUMENT = "SAVE_DOCUMENT"
const SAVE_DOCUMENTS = "SAVE_DOCUMENTS"
const SAVE_PERSONAL_DETAILS = "SAVE_PERSONAL_DETAILS"
const SAVE_PRESCRIPTION_AND_DOCTOR_NAME = "SAVE_PRESCRIPTION_AND_DOCTOR_NAME"
const SAVE_DELIVERY_DETAILS = "SAVE_DELIVERY_DETAILS"
const RESET_DETAILS = "RESET_DETAILS"
const SHOW_TOAST = "SHOW_TOAST"
const HIDE_TOAST = "HIDE_TOAST"
const SAVE_RECEIPT_ITEMS = "SAVE_RECEIPT_ITEMS"
const SAVE_RECEIPT = "SAVE_RECEIPT"
const SAVE_ACTIVE_DOCUMENT = "SAVE_ACTIVE_DOCUMENT"
const REMOVE_ACTIVE_DOCUMENT = "REMOVE_ACTIVE_DOCUMENT"
const SAVE_MEDENSURE_MEDICINES = "SAVE_MEDENSURE_MEDICINES"
const SAVE_HEALTH_SURVEY_MEDENSURE = "SAVE_HEALTH_SURVEY_MEDENSURE"
const SAVE_PERSONAL_DETAILS_MEDENSURE = "SAVE_PERSONAL_DETAILS_MEDENSURE"
const SAVE_CONTEXT_TO_SESSION = "SAVE_CONTEXT_TO_SESSION"
const GET_CONTEXT_FROM_SESSION = "GET_CONTEXT_FROM_SESSION"
const REMOVE_CONTEXT_FROM_SESSION = "REMOVE_CONTEXT_FROM_SESSION"

const modal = {
  SHOW_MODAL: "SHOW_MODAL",
  HIDE_MODAL: "HIDE_MODAL",
}

const slidingModal = {
  SHOW_SLIDING_MODAL: "SHOW_SLIDING_MODAL",
  HIDE_SLIDING_MODAL: "HIDE_SLIDING_MODAL",
  CLOSE_SLIDING_MODAL: "CLOSE_SLIDING_MODAL",
}

export let AppReducer = (state, action) => {
  switch (action.type) {
    case SAVE_AGREE_FLEXMED:
      return {
        ...state,
        flexmed: { ...state.flexmed, agreeToMechanics: action.payload },
      }
    case SAVE_AGREE_MEDENSURE:
      return {
        ...state,
        medensure: { ...state.medensure, agreeToMechanics: action.payload },
      }
    case SAVE_AGREE_CONSENT_FLEXMED:
      return {
        ...state,
        flexmed: { ...state.flexmed, agreeToConsent: action.payload },
      }
    case SAVE_AGREE_VIBER_CONSENT_FLEXMED:
      return {
        ...state,
        flexmed: { ...state.flexmed, agreeToViberConsent: action.payload },
      }
    case SAVE_REIMBURSEMENT_FOR:
      return {
        ...state,
        flexmed: {
          ...state.flexmed,
          reimbursementMethod: {
            ...state.flexmed.reimbursementMethod,
            ...action.payload,
          },
        },
      }
    case SAVE_AGREE_CONSENT_MEDENSURE:
      return {
        ...state,
        medensure: { ...state.medensure, agreeToConsent: action.payload },
      }
    case SAVE_AGREE_VIBER_CONSENT_MEDENSURE:
      return {
        ...state,
        medensure: { ...state.medensure, agreeToViberConsent: action.payload },
      }
    case SAVE_AGREE_EPHARMACY:
      return {
        ...state,
        epharmacy: { ...state.epharmacy, agreeToMechanics: action.payload },
      }
    case SAVE_MEDICINES:
      return {
        ...state,
        epharmacy: { ...state.epharmacy, medicines: action.payload },
      }
    case SAVE_MEDENSURE_MEDICINES:
      return {
        ...state,
        medensure: { ...state.medensure, medicines: action.payload },
      }

    case SAVE_ORDERING_FOR:
      return {
        ...state,
        orderingFor: action.payload,
      }
    case SAVE_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
      }
    case SAVE_DOCUMENT:
      return {
        ...state,
        documents: [...state?.documents, action.payload],
      }
    case SAVE_ACTIVE_DOCUMENT:
      return {
        ...state,
        activeDocument: { ...action.payload },
      }
    case REMOVE_ACTIVE_DOCUMENT:
      let newTempDocument = action?.payload
      let currentDocuments = [...state.documents]
      currentDocuments = currentDocuments.filter(
        document => document?.oldname !== newTempDocument?.oldname
      )
      currentDocuments = [...currentDocuments, newTempDocument]
      return {
        ...state,
        activeDocument: null,
        documents: currentDocuments,
      }
    case SAVE_PERSONAL_DETAILS:
      return {
        ...state,
        flexmed: { ...state.flexmed, ...action.payload },
      }
    case SAVE_PRESCRIPTION_AND_DOCTOR_NAME:
      return {
        ...state,
        flexmed: { ...state.flexmed, ...action.payload },
      }
    case SAVE_PERSONAL_DETAILS_MEDENSURE:
      return {
        ...state,
        medensure: { ...state.medensure, ...action.payload },
      }
    case SAVE_MEDENSURE_PRESCRIBING_DOCTOR:
      return {
        ...state,
        medensure: { ...state.medensure, ...action.payload },
      }
    case SAVE_DELIVERY_DETAILS:
      return {
        ...state,
        epharmacy: { ...state.epharmacy, ...action.payload },
      }
    case SAVE_HEALTH_SURVEY_MEDENSURE:
      return {
        ...state,
        medensure: { ...state.medensure, healthSurvey: action.payload },
      }
    case SAVE_RECEIPT_ITEMS:
      return { ...state, flexmed: { ...state.flexmed, ...action.payload } }
    case SAVE_RECEIPT:
      return {
        ...state,
        flexmed: {
          ...state.flexmed,
          receipts: [...action.payload],
        },
      }
    case RESET_DETAILS:
      return { ...initialState }

    case SHOW_TOAST:
      return { ...state, toast: { ...action.payload, isActive: true } }

    case HIDE_TOAST:
      return { ...state, toast: { ...initialState.toast } }

    case modal.SHOW_MODAL:
      return { ...state, modal: { ...action.payload, isActive: true } }

    case modal.HIDE_MODAL:
      return { ...state, modal: { ...initialState.modal } }

    case slidingModal.SHOW_SLIDING_MODAL:
      return { ...state, slidingModal: { ...action.payload, isActive: true } }

    case slidingModal.HIDE_SLIDING_MODAL:
      return {
        ...state,
        slidingModal: { ...initialState.slidingModal, isActive: false },
      }
    case slidingModal.CLOSE_SLIDING_MODAL:
      return {
        ...state,
        slidingModal: { ...state.slidingModal, isClosed: true },
        modal: { ...initialState.modal },
      }
    case SAVE_CONTEXT_TO_SESSION:
      let toBeSaved = {}
      for (let key in action.payload) toBeSaved[key] = action.payload[key]
      saveContextToSession({ ...toBeSaved })
      return toBeSaved
    case GET_CONTEXT_FROM_SESSION:
      let returnState = { ...getContextFromSession() }
      if (isObjectEmpty(returnState)) return { ...initialState }

      return {
        ...returnState,
        modal: {
          ...initialState.modal,
        },
        slidingModal: {
          ...initialState.slidingModal,
        },
      }
    case REMOVE_CONTEXT_FROM_SESSION:
      removeContextFromSession()
      return {
        ...initialState,
      }
    default:
      return
  }
}
