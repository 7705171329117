import React, { useReducer } from "react"

import Modal from "../components/Elements/Modal"
import SlidingModal from "../components/Elements/SlidingModal"
import Toast from "../components/Layout/Toast"

import { AppReducer } from "./AppReducer"

export const initialState = {
  documents: [],
  activeDocument: null,
  epharmacy: {
    agreeToMechanics: [],
    medicines: [],
    deliveryAddress: {
      addressType: "",
      streetAddress: "",
      houseNumber: "",
      province: "",
      city: "",
      notes: "",
    },
    nps: "",
    fullName: "",
    employeeNumber: "",
    mobileNumber: "",
    emailAddress: "",
    civilStatus: "",
    beneficiaryName: "",
    beneficiaryRelationship: "",
  },
  flexmed: {
    agreeToMechanics: [],
    agreeToViberConsent: [],
    firstName: "",
    lastName: "",
    prescriptionName: "",
    prescribingDoctor: "",
    employeeNumber: "",
    mobileNumber: "",
    viberNumber: "",
    emailAddress: "",
    personalEmail: "",
    designation: "",
    company: "",
    civilStatus: "",
    beneficiaryName: "",
    beneficiaryRelationship: "",
    reimbursementMethod: {
      reimbursingFor: "",
      reimbursingForName: "",
    },
    receipts: [],
    agreeToConsent: [],
    siteName: { value: "", label: "" },
    birthday: {
      month: { value: "", label: "" },
      date: { value: "", label: "" },
      year: { value: "", label: "" },
    },
    nps: "",
    bank: "",
    bankAccountNumber: "",
  },
  medensure: {
    firstName: "",
    middleName: "",
    lastName: "",
    employeeNumber: "",
    mobileNumber: "",
    viberNumber: "",
    emailAddress: "",
    personalEmail: "",
    designation: "",
    company: "",
    siteLocation: { value: "", label: "" },
    prescribingHospital: "",
    prescribingMEDoctor: "",
    prcNumber: "",
    prescriptionDate: {
      year: { value: "", label: "" },
      month: { value: "", label: "" },
      date: { value: "", label: "" },
    },
    age: "",
    sex: "",
    birthday: {
      month: { value: "", label: "" },
      date: { value: "", label: "" },
      year: { value: "", label: "" },
    },
    deliveryAddress: {
      addressType: "Office",
      streetAddress: "",
      houseNumber: "",
      province: {
        value: "Metro Manila",
        label: "Metro Manila",
      },
      city: "",
      barangay: "",
      siteName: "",
      notes: "",
      nps: "",
    },
    nps: "",
    agreeToMechanics: [],
    agreeToViberConsent: [],
    medicines: [],
    healthSurvey: {
      height: "",
      weight: "",
      usualBP: "",
      maxicareCardNumber: "",
      smokingHistory: "",
      medicalHistory: [],
      familyMedicalHistory: [],
      maxicareOfferings: [],
      programServiceRating: { value: "", label: "" },
      otherMedicalHistory: "",
      otherFamilyMedicalHistory: "",
      otherMaxicareOfferings: "",
    },
  },
  modal: {
    isCard: false,
    isFullheight: false,
    isActive: false,
    content: null,
    heading: "",
    class: null,
    headerClass: null,
    hideCloseButton: false,
    closeOnClickOutside: false,
    background: {
      color: null,
      opacity: "100",
    },
    hideOnMobile: false,
  },
  slidingModal: {
    isCard: false,
    isFullheight: false,
    isActive: false,
    isClosed: false,
    content: null,
    heading: "",
    headerClass: null,
    hideCloseButton: false,
    closeOnClickOutside: false,
    background: {
      color: null,
      opacity: "100",
    },
    isCloseable: true,
  },
  toast: {
    isActive: false,
    message: null,
    color: null,
  },
  feedback: {
    email: "",
    hasEncounteredChallengesAccessingTheWebsite: "",
    describeChallenges: "",
    suggestedChangesToWebsiteFlow: "",
  }
}

const AppContext = React.createContext(initialState)

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, { ...initialState })

  const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

  const handleCloseSlidingModal = async event => {
    dispatch({ type: "CLOSE_SLIDING_MODAL" })
    await delay(400)
    dispatch({ type: "HIDE_SLIDING_MODAL" })
  }

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <Modal
        isModalActive={state?.modal.isActive}
        closeModal={() => dispatch({ type: "HIDE_MODAL" })}
        heading={state?.modal.heading}
        modalHeaderClass={state?.modal.headerClass}
        modalCardClass={state?.modal.cardClass}
        modalBodyClass={state?.modal.bodyClass}
        hideCloseButton={state?.modal.hideCloseButton}
        isCard={state?.modal.isCard}
        hideOnMobile={state.modal.hideOnMobile}
      >
        {state?.modal.content}
      </Modal>
      <SlidingModal
        isModalActive={state?.slidingModal.isActive}
        isFullheight={state?.slidingModal.isFullheight}
        isModalClosed={state?.slidingModal.isClosed}
        hideModal={() => dispatch({ type: "HIDE_SLIDING_MODAL" })}
        closeOnClickOutside={state?.slidingModal.closeOnClickOutside}
        closeModal={handleCloseSlidingModal}
        heading={state?.slidingModal.heading}
        modalHeaderClass={state?.slidingModal.headerClass}
        modalBackground={state?.slidingModal.background}
        hideCloseButton={state?.slidingModal.hideCloseButton}
        isCard={state?.slidingModal.isCard}
      >
        {state.slidingModal.content}
      </SlidingModal>
      <Toast message={state?.toast.message} color={state?.toast.color} />
      {children}
    </AppContext.Provider>
  )
}

export { AppContext, AppProvider }
