export const initialState = {
  medensureServices: [],
  personalDetails: {
    employeeNumber: "",
    maxicareCardNumber: "",
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
  },
  updatePersonalDetails: {
    mobileNumber: "",
    email: "",
    deliveryAddress: {
      addressType: "",
      streetAddress: "",
      houseNumber: "",
      officeAddress: {
        label: "",
        value: "",
      },
      province: {
        label: "",
        value: "",
      },
      city: {
        label: "",
        value: "",
      },
      barangay: {
        label: "",
        value: "",
      },
      notes: "",
    },
  },
  updatedPrescription: {
    medicines: [],
    cancelledMedicines: [],
    agreeToConsent: [],
  },
  documents: [],
  activeDocument: null,
  modal: {
    isCard: false,
    isFullheight: false,
    isActive: false,
    content: null,
    heading: "",
    class: null,
    headerClass: null,
    hideCloseButton: false,
    closeOnClickOutside: false,
    background: {
      color: null,
      opacity: "100",
    },
    hideOnMobile: false,
  },
  slidingModal: {
    isCard: false,
    isFullheight: false,
    isActive: false,
    isClosed: false,
    content: null,
    heading: "",
    headerClass: null,
    hideCloseButton: false,
    closeOnClickOutside: false,
    background: {
      color: null,
      opacity: "100",
    },
    isCloseable: true,
  },
  agreeToConsent: [],
  visitedPages: []
}
