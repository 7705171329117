import React from "react"
import { ApolloProvider } from "react-apollo"
import { client } from "./src/services/apollo"

import { AppProvider } from "./src/context/AppContext"
import "./static/styles/global.scss"
import { RefillsProvider } from "./src/components/MedEnsure/Refills/services/RefillsContext"

export const wrapRootElement = ({ element }) => (
  <AppProvider>
    <RefillsProvider>
      <ApolloProvider client={client}>{element}</ApolloProvider>
    </RefillsProvider>
  </AppProvider>
)
