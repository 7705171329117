import {
  saveContextToSession,
  getContextFromSession,
  removeContextFromSession,
} from "../../../../services/context.js"
import { isObjectEmpty } from "../../../../services/general.js"

import { initialState } from "./RefillsInitialState.js"

const SAVE_ACTIVE_DOCUMENT = "SAVE_ACTIVE_DOCUMENT"
const REMOVE_ACTIVE_DOCUMENT = "REMOVE_ACTIVE_DOCUMENT"
const SAVE_CONTEXT_TO_SESSION = "SAVE_CONTEXT_TO_SESSION"
const GET_CONTEXT_FROM_SESSION = "GET_CONTEXT_FROM_SESSION"
const REMOVE_CONTEXT_FROM_SESSION = "REMOVE_CONTEXT_FROM_SESSION"
const SAVE_DOCUMENT = "SAVE_DOCUMENT"
const SAVE_DOCUMENTS = "SAVE_DOCUMENTS"
const SAVE_AGREE = "SAVE_AGREE"

const modal = {
  SHOW_MODAL: "SHOW_MODAL",
  HIDE_MODAL: "HIDE_MODAL",
}

const slidingModal = {
  SHOW_SLIDING_MODAL: "SHOW_SLIDING_MODAL",
  HIDE_SLIDING_MODAL: "HIDE_SLIDING_MODAL",
  CLOSE_SLIDING_MODAL: "CLOSE_SLIDING_MODAL",
}

export let RefillsReducer = (state, action) => {
  switch (action.type) {
    case modal.SHOW_MODAL:
      return { ...state, modal: { ...action.payload, isActive: true } }

    case modal.HIDE_MODAL:
      return { ...state, modal: { ...initialState.modal } }

    case slidingModal.SHOW_SLIDING_MODAL:
      return { ...state, slidingModal: { ...action.payload, isActive: true } }

    case slidingModal.HIDE_SLIDING_MODAL:
      return {
        ...state,
        slidingModal: { ...initialState.slidingModal, isActive: false },
      }
    case slidingModal.CLOSE_SLIDING_MODAL:
      return {
        ...state,
        slidingModal: { ...state.slidingModal, isClosed: true },
        modal: { ...initialState.modal },
      }
    case SAVE_AGREE:
      return {
        ...state,
        agreeToConsent: action.payload
      }
    case SAVE_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
      }
    case SAVE_DOCUMENT:
      return {
        ...state,
        documents: [...state?.documents, action.payload],
      }
    case SAVE_ACTIVE_DOCUMENT:
      return {
        ...state,
        activeDocument: { ...action.payload },
      }
    case REMOVE_ACTIVE_DOCUMENT:
      let newTempDocument = action?.payload
      let currentDocuments = [...state.documents]
      currentDocuments = currentDocuments.filter(
        document => document?.oldname !== newTempDocument?.oldname
      )
      currentDocuments = [...currentDocuments, newTempDocument]
      return {
        ...state,
        activeDocument: null,
        documents: currentDocuments,
      }
    case SAVE_CONTEXT_TO_SESSION:
      let toBeSaved = {}
      for (let key in action.payload) toBeSaved[key] = action.payload[key]
      saveContextToSession({ ...toBeSaved })
      return toBeSaved
    case GET_CONTEXT_FROM_SESSION:
      let returnState = { ...getContextFromSession() }
      if (isObjectEmpty(returnState)) return { ...initialState }

      return {
        ...returnState,
        modal: {
          ...initialState.modal,
        },
        slidingModal: {
          ...initialState.slidingModal,
        },
      }
    case REMOVE_CONTEXT_FROM_SESSION:
      removeContextFromSession()
      return {
        ...initialState,
      }
    default:
      return
  }
}
