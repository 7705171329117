import React, { useReducer } from "react"
import { RefillsReducer } from "./RefillsReducer"
import { initialState } from "./RefillsInitialState"

const RefillsContext = React.createContext(initialState)

const RefillsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(RefillsReducer, { ...initialState })

  const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

  return (
    <RefillsContext.Provider value={{ state, dispatch }}>
      {children}
    </RefillsContext.Provider>
  )
}

export { RefillsContext, RefillsProvider }
